



import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ProductName } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState({
      qrCode: "qrCode"
    })
  },
  mounted() {
    dispatch.learnMore(this.$route.query.p as ProductName);
    setTimeout(() => {
      document
        .querySelector(".v-qr-code-dialog .md-dialog-container .iconfont")
        ?.setAttribute("style", "visibility: hidden;");
    }, 10);
  },
  beforeDestroy() {
    dispatch.qrCodeSet({
      ...this.qrCode,
      visible: false
    });
  }
});
